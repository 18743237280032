/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getFromApiFunction } from "../../services/apiFunctions.tsx";

const barChatOption: any = (graphTitle: string, chartData: any = null) => {
  const plugins = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: graphTitle,
    },
  };

  if (chartData) {
    plugins["tooltip"] = {
      callbacks: {
        label: function (context) {
          const index = context.dataIndex;
          const itemData = context.dataset.data[index];
          const label = itemData.verbose || context.dataset.label;

          return `${label}: ${itemData.value}`;
        },
      },
    };
  }

  const options = {
    responsive: true,
    plugins,
    scale: { ticks: { precision: 0 } },
    legend: { display: false },
    parsing: {
      yAxisKey: "value",
      xAxisKey: "label",
    },
  };

  return options;
};

const transformToDataSet = (graphTitle: string, data: any, color?: any) => {
  return {
    datasets: [
      {
        label: graphTitle,
        data,
        backgroundColor: color ?? "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
);

export const tempOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    backgroundColor: "#ebab34",
  },
};

interface BarChartProps {
  graphName: string;
  baseEndpoint: string;
  statsUnitProps: string;
  paramsProps: any;
  color: string;
  customHeigth?: string;
  chartType?: any;
  perRegionData?: boolean;
  defaultParams?: any;
  datasetLabel?: string;
}

export default function BarChart(props: BarChartProps) {
  const {
    graphName,
    baseEndpoint,
    statsUnitProps,
    paramsProps,
    color,
    customHeigth,
    chartType,
    perRegionData,
    defaultParams,
    datasetLabel,
  } = props;
  const [chartData, setChartData] =
    useState<ChartData<"bar", (number | [number, number] | null)[], unknown>>();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [statsUnit, setStatsUnit] = useState(statsUnitProps);
  const [params, setParams] = useState(paramsProps);

  const firstRender = useRef(true); //To check if component is mounted

  const charComponent = chartType || Bar;

  const getEndPointSuffix = function () {
    if (perRegionData === false) return "";
    return `by_${statsUnit}/`;
  };

  const getChartData = async () => {
    setApiCalling(true);
    setHasError(false);
    if (
      statsUnit === "commune" &&
      !(params && (params.get("departement") || params.get("pole")))
    )
      return;

    if (
      statsUnit !== "commune" &&
      params &&
      (params.get("departement") || params.get("pole"))
    )
      return;

    const endPointSuffix = getEndPointSuffix();

    await getFromApiFunction(`${baseEndpoint}${endPointSuffix}`, params)
      .then((response: any) => {
        setChartData(
          transformToDataSet(datasetLabel || graphName, response.data, color),
        );
        setApiCalling(false);
      })
      .catch((error: any) => {
        setHasError(true);
        setApiCalling(false);
      });
  };

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {
    const params = new URLSearchParams();
    [paramsProps, defaultParams].forEach((searchParams) => {
      if (searchParams) {
        searchParams.forEach((value, name) => {
          params.append(name, value);
        });
      }
    });
    setParams(params);
  }, [paramsProps]);

  useEffect(() => {
    getChartData();
  }, [statsUnit, params]);

  return (
    <>
      {!apiCalling && !hasError && (
        <Bar
          options={barChatOption(graphName, chartData)}
          height={customHeigth || "500px"}
          width={"800px"}
          data={
            chartData as ChartData<"bar", (number | [number, number] | null)[], unknown>
          }
          className="w-fit h-fit"
        />
      )}
    </>
  );
}
