import not_found from "../assets/img/not_found.png";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={not_found} alt="Not Found" className="mb-4 w-[200px]" />
      <h1 className="text-4xl font-bold mb-4">404 - Not Found</h1>
      <p className="text-lg">Cette page n'existe pas.</p>
      <a href="/" className="mt-4 text-blue-500 hover:underline">
        Retourner à l'accueil
      </a>
    </div>
  );
}
