/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Control from "react-leaflet-custom-control";
import country from "../../shapes/departement.json";
import { MAP_VIEWS } from "../../constants.tsx";
import ViewController from "./ViewController.tsx";
import ZoomControlWithReset from "./ZoomWithReset.js";
import { getFromApiFunction } from "../../services/apiFunctions.tsx";
import IndicateurSuivi from "./IndicateurSuivi.tsx";
import IndicateurSuiviButton from "./IndicateurSuiviButton.tsx";
import { Modal } from "antd";
import { downloadFile } from "../../services/fileServices.tsx";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

function PopupComponent(props) {
  const { marker, layerControl } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const map = useMap();
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <a
          role="button"
          onClick={() => map.flyTo([marker.latitude, marker.longitude], 18)}
        >
          Zoomer
        </a>
        {marker.category === "project" && (
          <div className="font-bold">{marker.financial_partner}</div>
        )}
        {/* <div className="font-bold">Nom</div> */}
        <div className="font-bold">
          {marker.category === "project" ? "Projet" : "ONG"}
        </div>
        <div>{marker.name}</div>
        <div className="font-bold">Partenaires de mise en oeuvre</div>
        <div>{marker.other_partners}</div>
        <div className="font-bold">E-Mail</div>
        <div>{marker.contact}</div>
        <div className="font-bold">Contact</div>
        <div>{marker.contact_phone}</div>
        <div className="font-bold">Domaines d'intervention</div>
        <div>{marker.intervention_fields_aggregate}</div>
        <div className="font-bold">Zones d'intervention</div>
        <div>{marker.intervention_zones_aggregate}</div>
        <div className="font-bold">Bénéficiaires</div>
        <div>{marker.intervention_targets_aggregate}</div>
        {marker.category === "project" && (
          <>
            <div className="font-bold">Durée</div>
            <div>{marker.period}</div>
          </>
        )}
        <a onClick={() => setIsModalOpen(true)} className="cursor-pointer">
          Voir photo
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            downloadFile(
              `${marker.category}s/${marker.id}/report/`,
              `${marker.category === "organisation" ? "ONG" : "Projet"}-${marker.name}.pdf`,
              "application/pdf",
            )
          }
          className="cursor-pointer"
        >
          Télécharger la fiche
        </a>
        <Modal
          title="Photo du siège"
          open={isModalOpen}
          cancelButtonProps={{ style: { display: "none" } }}
          okType="default"
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
        >
          <img src={marker.image} />
        </Modal>
      </div>
    </Popup>
  );
}

function Mapping() {
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[0]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[0].id);
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState(
    DEFAULT_MARKER_PER_CONTROL_LAYER,
  );
  const [map, setMap] = useState();

  const changeView = (viewToSet) => {
    setView(viewToSet);
    setTileKey(viewToSet.id);
  };

  const [markers, setMarkers] = useState([]);
  const addMarkers = (list, category) => {
    setMarkers([
      ...markers,
      ...list.map((item) => {
        item.category = category;
        return item;
      }),
    ]);
  };

  // useEffect(() => {
  //   getFromApiFunction("/projects/").then((response) => {
  //     setMarkers(response.data.filter((item) => item.longitude && item.latitude))
  //   })
  // }, []);

  const [isFiltersOpen, openFilters] = useState();

  return (
    <div className="w-full h-full" id="mapping">
      <div className="border-4 border-white w-full h-full min-h-max container mx-auto rounded-3xl">
        <IndicateurSuivi
          isOpen={isFiltersOpen}
          onClose={() => openFilters(false)}
          setMarkers={setMarkers}
          addMarkers={addMarkers}
        />
        <IndicateurSuiviButton onClick={() => openFilters(true)} />
        <MapContainer bounds={bounds} zoomControl={false}>
          <ZoomControlWithReset bounds={bounds} />
          <FeatureGroup>
            {markers
              ? markers.map((marker, index) => {
                  const markerkey = index;
                  return (
                    <Marker
                      position={[marker.latitude, marker.longitude]}
                      key={markerkey}
                    >
                      <PopupComponent
                        marker={marker}
                        // layerControl={layerControl}
                      />
                    </Marker>
                  );
                })
              : null}
          </FeatureGroup>
          <Control prepend position="topright">
            <ViewController selectedMapView={view} onMapViewChanged={changeView} />
          </Control>
          <TileLayer key={tileKey} {...view.tileLayerInfos} />
          <GeoJSON
            data={country}
            onEachFeature={(feature, layer) =>
              layer.setStyle({ fillColor: "yellow", color: "transparent" })
            }
          />
        </MapContainer>
      </div>
    </div>
  );
}

export default Mapping;
